import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

export default function RbCard() {
  return (
    <div>
      <Card style={{ width: "18rem" }}>
        <Card.Img variant="top" src="https://shreesoftit.com/images/logo.png" />
        <Card.Body>
          <Card.Title>Shree Soft IT</Card.Title>
          <Card.Text>IT</Card.Text>
          <Button variant="primary">Jon Now</Button>
        </Card.Body>
      </Card>
    </div>
  );
}
