import logo from "./logo.svg";
import "./App.css";
import RbButton from "./pages/rbbutton";
import RbCard from "./pages/rbCard";

function App() {
  return (
    <div className="App">
      {/* <RbButton bVariant="success" pageName="Buttons" /> */}

      <RbCard />
    </div>
  );
}

export default App;
